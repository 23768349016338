.App {
  text-align: center;
}

 


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
  
}

th {
  background-color: #eee;
  border: 1px solid #ddd;
  padding: 8px;
}

td {
  border: 1px solid #ddd;
  padding: 8px;
}

@media (max-width: 768px) {
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  th, td {
    min-width: 100px;
  }
  .landing-input-form{
    width: 100% !important;
  }
  .feedback-form{
    width: 100% !important;
  }
}
body {
  margin: 40px;
}

.container {
  display: flex;
  flex-direction: row;
}

.navigation {
  flex: 1;
  margin-right: 20px;
}

.feedback {
  flex: 1;
}

 
.feedback label {
  display: block;
  margin-bottom: 10px;
}

.feedback input[type="radio"] {
  margin-right: 5px;
}

.feedback input[type="text"] {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.feedback input[type="submit"] {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  max-width: 400px;
  margin: 0 auto;
}


form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 70%;
  padding: 20px;
 
  border: 1px solid #ccc;
  border-radius: 5px;
}

.actionable-insight   {
   
  width: 70%;
 
}

.actionable-insight-intro   {
  width: 70%;
  line-height: 1.6;
 
}

@media (max-width: 767px) {

  .actionable-insight-intro   {
    width: 100% !important;
    line-height: 1.6;
   
  }
  .actionable-insight   {
    width: 100% !important;
    padding-right: 15px;
    line-height: 1.6;
  }

  .form-container {
    width: 100% !important;
    margin: 0;
  }
 
    form {
      width: auto; /* Remove the width property for mobile devices */
  }
  .contact-email {
    max-width: none !important; 
  }
  .App-logo img {
    max-width: 300px;
  }
}

.feedback input[type="submit"]:hover {
  background-color: #3e8e41;
}
h2 {
  text-align: center;
  margin-bottom: 20px;
}

div {
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

.call-to-action {
  text-align: center;
  margin-top: 20px;
}

button[type="submit"] {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}


input[type="text"],
select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}


.report-container {
  max-width: 70%;
  /*margin: 0 auto;*/
  text-align: left;
}
@media (max-width: 767px) {
  .report-container {
    max-width: 100%;
    padding: 0 0px; /* Optional: Add padding for mobile view */
  }
}

.section-container{
  margin-top: 30px;
}

.report-container h2 {
  text-align: center;
}


.menu {
  flex: 0 0 20%; /* Adjust the width as needed */
  background: #f0f0f0;
  padding: 20px;
}

.content {
  flex: 1;
  padding: 20px;
  overflow-y: auto; /* Enable vertical scrolling if necessary */
}
.success-message {
  color: green;
  font-weight: 600;
}
.loading-message {
  color: green;
  font-weight: 600;
  font-size: xx-large;
}
.error-message {
  color: orange;
  font-weight: 600;
  font-size: larger;
}
.info-panel {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.info-panel p {
  margin: 0;
}
.roadmap-container {
  max-width: 600px;
  margin: 0 auto;
}

.roadmap-list {
  list-style-type: none;
  padding: 0;
}

.roadmap-item {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.roadmap-item-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.roadmap-item-description {
  font-size: 14px;
  color: #555;
}

.contact-container{
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.contact-textarea {
  padding: 8px;
  height: 200px;
  width: 400px;
}

@media (max-width: 600px) {
  .contact-textarea {
    width: 100%;
  }
}

.contact-email {
  padding: 8px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 10px;
  
}
.divider {
  border: none;
  border-top: 1px solid #ccc;
  margin: 60px 60px;
}


.about-container {
  max-width: 70%;
  /*margin: 0 auto;*/
  text-align: left;
}
@media (max-width: 767px) {
  .about-container {
    max-width: 100%;
    padding: 0 0px; /* Optional: Add padding for mobile view */
  }

  .report-navigation .menu-items {
 
    display: grid!important;
  }

  .menu-items .dropdown-item {
    min-height: 38px; /* Adjust the minimum height as needed */
  }

  .menu-items .dropdown-item {
  
    padding-top: 18px  ; /* Adjust the margin-bottom as needed */
    padding-bottom: 18px  ;
  }
 
}
.about-container p {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.leading-text {
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 1rem;
 
    color: #33645a; /* Update the color value here */
}
.cta-sentence {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  width: 70%;
  margin: 0 auto;
  color: #33645a; /* Update the color value here */
  margin-top: 40px;
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  opacity: 0.9;
  z-index: 9999;
}
.share-button {
  padding: 10px 20px;
  background-color: #33645a;
  color: #fff;
  border: none;
  border-radius: 5px;
 
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  transition: background-color 0.3s ease;
  border: none;
  border-radius: 5px;
  cursor: pointer;
 
  margin-right: 8px;
}
.share-button:hover {
  background-color: #d4d4d4;
}

.share-icon {
  margin-right: 8px;
}
 
.share-button-container {
  display: flex;
  justify-content: flex-end;
 
}


.new-report-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 8px;
  color: #333;
  transition: background-color 0.3s ease;
 

}
.regenerate-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;

  color: #333;
  transition: background-color 0.3s ease;
 

}

.new-report-button:hover {
  background-color: #e0e0e0;
}
.regenerate-button:hover {
  background-color: #e0e0e0;
}
.new-report-icon {
  margin-right: 5px;
}
.report-navigation{
  font-size: large;
  background-color: #f5f5f5;
  padding: 10px 0px;
}
.report-navigation a{
  margin-right: 20px;
  padding-left: 20px  ;
  color: #333;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}
.report-navigation a:hover{
  color: #777;
}

.report-navigation a:active{
  color: #12a587;
}

.report-navigation .menu-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.report-navigation .menu-items li {
  margin-right: 20px;
}

.report-navigation .menu-items li:last-child {
  margin-right: 0;
}

.casedesc {
  text-align: center;
  font-size: x-large;
  color: cadetblue;
}
.list-item{
  padding-bottom: 10px;

}
.reference {
  margin-bottom: 25px;
  padding: 10px;
  border: 1px solid  #d3d3d3de;
  border-color: #d3d3d3de;
  border-radius: 15px;
  padding-left: 20px;
  max-width: 80%;
  text-decoration: none;
  color: #333;
}

@media (max-width: 768px) {
  .reference {
      max-width: none; /* Remove the max-width property for mobile devices */
  }
}

.reference a{
  
  text-decoration: none;
  color: #333;
}
.reference a:hover{
  color:#0056b3;
}
.reference-text{
 color:#555;
}
.tag {
  display: inline-block;
  background-color: #f2f2f2;
  color: #555555;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 8px;
  float: right;
}
 
 
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: rgba(196, 196, 196, 0.568) !important;
  border-style: solid !important;
  border-width: 1px !important;
}
.report-page-title{
  margin-bottom: 28px;
}
.report-section-title{
  margin-top: 28px;
}

 
.full-width {
  width: 100%;
}
.feedback-form{
  display:block;
  margin-top:28px;
}
 
.landing-input-form{
  display:block;
  margin-top:28px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 18px;
  width: 700px;
}
.before-feedback{
  margin-top:38px;
}

.statuson{
  background-color: #0056b3;
  color:white
}

.filter-options .button:active{
  background-color: #0056b3;
}
.filter-options .button{
  border-radius:5px;
}

.dotted-underline {
color:gray !important;
  text-decoration: none !important;
  cursor: 'pointer' !important;
  border-bottom: 1px dashed #999 !important;
  text-decoration: none; 
  padding:  0px 5px !important;
  padding-bottom: 3px !important;
  margin-left: 3px !important;
}
.dotted-underline:hover {
  cursor: default;
  background-color: #6bdac3 !important;
  color: #333 !important;
}
@media (min-width: 1200px) {
 
.section-container h4 {
  font-size: 1.2rem !important;
}
}
.panel-left{
  text-align: left;
  font-size: larger !important;
  /* margin: auto; */
  margin-left: 3rem !important;
  margin-right: 55px;
  padding-left: 1rem !important;
  padding-right: 1rem;
  line-height: 2.25rem;
}
.panel-left h2{
  text-align: left !important;
 margin-top: 35px!important;
 font-weight: 700 !important;
}
.panel-left .contact-link{
 text-decoration: underline !important;
 color: #0056b3 !important;
 cursor: pointer;
}
@media (max-width: 767px) {
  .panel-left{
   
     margin: auto !important;  
  
  }
  .panel-left .contact-form  {
    width: 100% !important;
    margin: auto !important;
  }
  .panel-left .contact-container{
    width: 100% !important;
    margin: auto !important;
  }

 

}
.topic-button {
  padding: 4px 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #f8f9fa;
  color: #333;
  border: 1px solid #ccc;
  margin-right: 8px;
}

.topics-container {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}


/** AI Observations Modal */


/* Generate Button */
.modal-form button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

/* Generate Button Hover */
.modal-form button:hover {
  background-color: #0056b3;
}

.btn-cancel{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #f0f0f0 !important;
  border: 1px solid #ccc  !important;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 8px;
  color: #333 !important;
  transition: background-color 0.3s ease;
}

.text-warning{
  color:orange !important;
}
/* Result Text */
.result {
  font-size: 16px;
  margin-top: 20px; /* Increase or decrease margin as needed */
}

/** END AI Observations Modal */

 
.personalize-section {
  background-color: #f8f8f8; /* Light gray background */
  border: 1px solid #ddd; /* Gray border */
  padding: 20px; /* Padding around the content */
  margin-top: 20px; /* Margin from the top */
  border-radius: 8px; /* Rounded corners */
}

.personalize-section h5 {
  color: #333; /* Dark text color for the heading */
}

